<template>
    <router-view/>
</template>
<script>

export default {
    created() {
        //在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem('stateTagsList')) {
            //replaceState，替换store的根状态
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('stateTagsList'))))
        }

        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener('beforeunload', () => {
            let obj = {
                stateTagsList: this.$store.state.stateTagsList
            }
            sessionStorage.setItem('stateTagsList', JSON.stringify(obj))
        })
    },
}
</script>

<style lang="less">

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

.upload {
    .el-upload {
        display: block !important;
    }
}
.pic_upload{
    .el-upload-list {
        display: none !important;
    }
}

.case_pic_upload{
	.el-upload--picture-card{
		--el-upload-picture-card-size: auto !important;
	}
}

.el_form_item_child{
	.el-form-item__label{
		justify-content : flex-start !important;
	}
}

.center_label{
	.el-form-item{
		display: block;
	}
	.center_label_item{
		.el-form-item__label{
			justify-content: center;
			padding: 0;
		}
	}
}

.upload-demo{
	.el-upload-dragger{
		padding: 100px 10px;
	}
}

</style>
