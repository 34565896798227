<template>
    <el-container class="el-container">
        <el-aside class="el-aside">
			<el-menu class="el-menu" :default-active="this.$route.path" @select="changeSidebar"
					 :collapse="this.$store.state.isCollapse"
					 :default-openeds="[this.$route.path.substr(1).substr(0, this.$route.path.substr(1).indexOf('/'))]">
				<el-menu-item index="/index">
					<img src="../../assets/logo.jpg" style="width: 24px;">
					<span style="font-size: 16px; font-weight: bold; margin-left: 5px; margin-top: 2px;">爱嘻AI</span>
				</el-menu-item>
				<myMenu :menuList="menuList"></myMenu>
			</el-menu>
        </el-aside>
        <el-container>
            <el-header>
                <appHead></appHead>
            </el-header>
            <el-main class="el-main">
                <!-- 仔细看，核心在这，这里是路由的出口-->
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<style scoped lang="less">
.el-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    padding-bottom: 10px;
}

.el-aside {
	max-width: 350px;
	min-width: 200px;
    height: 100%;
    border-right: solid 1px var(--el-border-color);
    overflow-y: auto;
    width: auto;
}
.el-menu {
	border: none;
}
.el-main {
    height: 100%;
    overflow-y: auto;
    padding: 0px 20px ! important;
}
</style>

<script>
import appHead from '@/components/head/appHead';
import myMenu from '@/components/myMenu';
import menuJson from './menu.json';
import axios from "@/axios/request";
export default {
    name: 'home',
    data() {
        return {
            menuList: {}
        }
    },
    components: {
        appHead,
		myMenu
    },
    watch: {},
    setup() {
    },
    beforeMount() {
		this.loadJson();
    },
    mount() {

    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    methods: {
		changeSidebar: function (path) {
			this.$router.push(path);
		},
		loadJson() {
			this.menuList = menuJson;
		}
    }
}
</script>
