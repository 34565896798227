<template>
    <div class="head_div">
        <div class="el-icon-div">
            <el-icon v-if="!flodOrExpand" @click="menuFold()">
                <Fold/>
            </el-icon>
            <el-icon v-if="flodOrExpand" @click="menuExpand()">
                <Expand/>
            </el-icon>
        </div>
        <el-dropdown class="name">
            <span class="el-dropdown-link">
                {{ realName }}
                <el-icon>
                    <arrow-down/>
                </el-icon>
            </span>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="logOut()">退出登录</el-dropdown-item>
<!--                    <el-dropdown-item @click="changePSW()">修改密码</el-dropdown-item>-->
<!--                    <el-dropdown-item @click="setPasswordTwo()">二级密码</el-dropdown-item>-->
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
    <appTag/>

<!--    <el-dialog v-model="changePSWFormVisible" title="修改密码" :show-close="false" :close-on-press-escape="false"-->
<!--               :close-on-click-modal="false">-->
<!--        <el-form ref="changeForm" :model="pswForm" label-width="100px" :rules="rules">-->
<!--            <div class="form_div">-->
<!--                <el-form-item label="旧密码" prop="oldPassword">-->
<!--                    <el-input type="password" v-model="pswForm.oldPassword" placeholder="请输入旧密码" show-password/>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="新密码" prop="newPassword">-->
<!--                    <el-input type="password" v-model="pswForm.newPassword" placeholder="请输入新密码" show-password/>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="确认密码" prop="repeatNewPassword">-->
<!--                    <el-input type="password" v-model="pswForm.repeatNewPassword" placeholder="请输入确认密码" show-password/>-->
<!--                </el-form-item>-->
<!--            </div>-->
<!--            <el-link :underline="false" style="font-size:12px; color: var(&#45;&#45;el-color-primary);">至少8-16个字符，至少1个大写字母，1个小写字母和1个数字</el-link>-->
<!--        </el-form>-->
<!--        <template #footer>-->
<!--            <span class="dialog-footer">-->
<!--                <el-button type="danger" @click="cancelChange()">取消</el-button>-->
<!--                <el-button type="primary" @click="okChange()">保存</el-button>-->
<!--            </span>-->
<!--        </template>-->
<!--    </el-dialog>-->
<!--    <el-dialog v-model="setPasswordTwoFormVisible" title="二级密码" :show-close="false" :close-on-press-escape="false"-->
<!--               :close-on-click-modal="false">-->
<!--        <el-form ref="setPasswordTwoForm" :model="setPasswordTwoForm" label-width="100px" :rules="passwordRules">-->
<!--            <div class="form_div">-->
<!--                <el-form-item label="二级密码" prop="passwordTwo">-->
<!--                    <el-input maxlength="6" type="password" v-model="setPasswordTwoForm.passwordTwo" placeholder="请输入二级密码" show-password/>-->
<!--                    <el-link :underline="false" style="font-size:12px; color: var(&#45;&#45;el-color-primary);">二级密码为6位数字</el-link>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="验证码" prop="code">-->
<!--                    <el-input maxlength="6" class="v_code" v-model="setPasswordTwoForm.code" placeholder="请输入验证码"/>-->
<!--                    <el-button class="v_code_button" type="primary" :disabled="vCodeIsDisabled" @click="getVCode()">-->
<!--                        <p v-if="!vCodeIsDisabled">{{vCodeText}}</p>-->
<!--                        <p v-else>重新获取({{vCodeTime}}s)</p>-->
<!--                    </el-button>-->
<!--                </el-form-item>-->
<!--            </div>-->

<!--        </el-form>-->
<!--        <template #footer>-->
<!--            <span class="dialog-footer">-->
<!--                <el-button type="danger" @click="cancelSetPasswordTwo()">取消</el-button>-->
<!--                <el-button type="primary" @click="okSet()">保存</el-button>-->
<!--            </span>-->
<!--        </template>-->
<!--    </el-dialog>-->
</template>

<script>
import axios from "@/axios/request";
import {ElMessage} from 'element-plus'
import {ArrowDown} from '@element-plus/icons-vue'
import appTag from '@/components/head/appTag'
import { rsa } from "@/util/util";

export default {
    name: 'appHead',
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入确认密码"));
            } else if (value !== this.pswForm.newPassword) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };

        return {
            realName: "",
            flodOrExpand: false,
            changePSWFormVisible: false,
            setPasswordTwoFormVisible: false,
            pswForm: {
                oldPassword: "",
                newPassword: "",
                repeatNewPassword: "",
            },
            setPasswordTwoForm:{
                code:"",
                passwordTwo:"",
                codeId:"",
				sessionId:""
            },
            vCodeIsDisabled: false,
            vCodeText:"获取验证码",
            vCodeTime:60,
            rules: {
                oldPassword: [
                    {required: true, message: '请输入旧密码', trigger :  'blur'},
                    {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/, message: '密码格式不正确', trigger :  'blur'},
                ],
                newPassword: [
                    {required: true, message: '请输入新密码', trigger :  'blur'},
                    {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/, message: '密码格式不正确', trigger :  'blur'},
                ],
                repeatNewPassword: [
                    {required: true, validator: validatePass, trigger :  'blur'},
                    {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/, message: '密码格式不正确', trigger :  'blur'},
                ]
            },
            passwordRules: {
                passwordTwo: [
                    {required: true, message: '请输入二级密码', trigger :  'blur'},
                    {pattern: /^\d{6}$/, message: '二级密码格式不正确', trigger :  'blur'},
                ],
                code: [
                    {required: true, message: '请输入验证码', trigger :  'blur'},
                    {pattern: /^\d{6}$/, message: '验证码格式不正确', trigger :  'blur'},
                ]
            },
        }
    },
    components: {
        appTag
    },
    watch: {},
    setup() {
    },
    beforeMount() {
        let token = localStorage.getItem("docker-token");
        if (!token) {
            this.$router.push('/login')
        } else {
            let userBase = JSON.parse(localStorage.getItem("userBase"));
            this.realName = userBase.org + "  " + userBase.nickName;
        }
    },
    mount() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    methods: {
        logOut: function () {
            localStorage.clear();
            sessionStorage.clear();
            this.$store.state.stateTagsList = [{"name": "个人中心", "url": "/index"},{"name": "病例列表", "url": "/case/userCase"}];
            this.$router.push('/login')
        },
        menuFold: function () {
            this.$store.commit('changeIsCollapse', true);
            this.flodOrExpand = true;
        },
        menuExpand: function () {
            this.$store.commit('changeIsCollapse', false);
            this.flodOrExpand = false;
        },
        changePSW: function () {
            let that = this;
            that.changePSWFormVisible = true;
        },
        cancelChange: function () {
            let that = this;
            that.changePSWFormVisible = false;
            that.pswForm.oldPassword = "";
            that.pswForm.newPassword = "";
            that.pswForm.repeatNewPassword = "";
        },
        okChange: function () {
            let that = this;
            that.$refs.changeForm.validate(result => {
                if (result) {
                    rsa(that.pswForm, ['oldPassword','newPassword','repeatNewPassword'], function (thisForm){
                        axios.post(
                            '/sysUser/updateSysPassword',
                            thisForm
                        ).then(res => {
                            if (res.data.code === "0") {
                                ElMessage.success(res.data.msg + ",请重新登录");
                                that.changePSWFormVisible = false;
                                that.pswForm.oldPassword = "";
                                that.pswForm.newPassword = "";
                                that.pswForm.repeatNewPassword = "";
                                that.logOut();
                            }
                        })
                    })
                }
            })

        },
        setPasswordTwo: function (){
            let that = this;
            that.setPasswordTwoFormVisible = true;
        },
        cancelSetPasswordTwo: function () {
            let that = this;
            that.setPasswordTwoFormVisible = false;
            that.vCodeIsDisabled = false;
            that.setPasswordTwoForm = {};
        },
        getVCode: function (){
            let that = this;
            axios.post(
                '/sysUser/getPasswordTwoCode',
                {}
            ).then(res => {
                if (res.data.code === "0") {
                    that.setPasswordTwoForm.codeId = res.data.data.codeId;
                    that.vCodeIsDisabled = true;
                    let timeInterval = setInterval(() =>{
                        that.vCodeTime = that.vCodeTime - 1;
                        if (that.vCodeTime === 0){
                            clearInterval(timeInterval);
                            that.vCodeIsDisabled = false;
                        }
                    },1000)
                }
            })
        },
        okSet: function (){
            let that = this;
            that.$refs.setPasswordTwoForm.validate(result => {
                if (result) {
                    rsa(that.setPasswordTwoForm, ['passwordTwo'], function (thisForm){
						console.log(thisForm)
                        axios.post(
                            '/sysUser/updatePasswordTwo',
                            thisForm
                        ).then(res => {
                            if (res.data.code === "0") {
                                ElMessage.success(res.data.msg);
                                that.setPasswordTwoFormVisible = false;
                                that.vCodeIsDisabled = false;
                                that.setPasswordTwoForm = {};
                            }
                        })
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.head_div {
    height: 32px;
    width: 100%;
    line-height: 32px;
    border-bottom: solid 1px var(--el-border-color);
}

.el-icon-div {
    font-size: 18px;
    float: left;
    margin-top: 4px;
    cursor: pointer;
}

.head_div p {
    display: inline-block;
    float: left;
    margin: 0 0 0 15px;
    font-size: 24px;
}

.el-dropdown-link {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.name {
    display: inline-block;
    float: right;
    font-size: 15px;
    margin: 10px 15px 0 0;
}

.form_div {
    width: 360px;
    margin: 0 auto;
    .v_code{
        width: 135px;
        margin-right: 2px;
    }
    .v_code_button{
        width: 123px;
    }
}
</style>
